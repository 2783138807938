<template>
  <div>
    <div>
      <router-link :to="{ name: 'admin.categories.create' }" class="btn btn-theme btn-sm float-end"><i class="lni lni-plus me-2"></i>Add New</router-link>
      <h2>Property Categories</h2>
    </div>
    <loading v-if="loading" />
    <div class="row mt-3" v-if="!loading">
      <div class="col-md-6">
        <div class="card">
          <div class="">
            <ul class="nav flex-column nav-custom">
              <li
                :class="`nav-item border-bottom ${
                  selected_category.id == category.id ? 'active' : ''
                }`"
                v-for="(category, i) in categories"
                :key="`category-${i}`"
              >
                <a
                  class="nav-link float-start"
                  href="#"
                  @click.prevent="selected_category = category"
                  >{{ category.name }}</a
                >
                <a
                  class="nav-link float-end"
                  @click.prevent="destroy(category)"
                  title="Delete"
                  href="#"
                  ><i class="lni lni-trash-can"></i
                ></a>
                <router-link
                  class="nav-link float-end"
                  :to="{ name: 'admin.categories.edit', params: { id: category.id }}"
                  title="Edit"
                  ><i class="lni lni-pencil-alt"></i
                ></router-link>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="col-md-6">
        <div class="card">
          <div class="">
            <ul class="nav flex-column">
              <li
                class="nav-item border-bottom"
                v-for="(category, i) in selected_category.children"
                :key="`category-${i}`"
              >
                <a
                  class="nav-link float-start"
                  href="#"
                  @click.prevent="selected_category = category"
                  >{{ category.name }}</a
                >
                <a
                  class="nav-link float-end"
                  @click.prevent="destroy(category)"
                  title="Delete"
                  href="#"
                  ><i class="lni lni-trash-can"></i
                ></a>
                <router-link
                  class="nav-link float-end text-danger"
                  :to="{ name: 'admin.categories.edit', params: { id: category.id }}"
                  title="Edit"
                  ><i class="lni lni-pencil-alt"></i
                ></router-link>
              </li>
              <li
                class="nav-item border-bottom"
                v-if="selected_category.children"
              >
              <div class="alert alert-info mb-0" v-if="selected_category.children.length == 0">There are no child categories</div></li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  mounted() {
    this.fetch();
  },

  data() {
    return {
      categories: [],
      loading: true,
      selected_category: {},
    };
  },

  methods: {
    fetch() {
      this.loading = true;

      this.axios.get("/api/v1/admin/categories").then((response) => {
        this.categories = response.data.categories;
        this.selected_category = response.data.categories[0];
        this.loading = false;
      });
    },

    destroy(category) {
      this.loading = true;

      this.axios.delete(`/api/v1/admin/categories/${category.id}`).then(() => {
        this.loading = false;
        this.fetch();
        this.$swal({
          title: "Deleted",
          text: "The category has been deleted",
          type: "success",
        });
      });
    },
  },
};
</script>
